
import { defineComponent, PropType, ref } from 'vue'
import {
  ElDialog,
  UploadFile,
  UploadProps,
  ElIcon,
  ElButton,
  ElFormItem,
  ElNotification,
  ElUpload,
} from 'element-plus'
import { Delete, UploadFilled } from '@element-plus/icons-vue'

import { maxFileSizesInMb, acceptFileFormats } from '@/config'
import { apiErrorNotification } from '@/core/helpers'
import { filesService } from '@/services'
import { IUpdatePosterForm } from '@/types'

export default defineComponent({
  name: 'ArAnchorUploadModal',

  components: {
    ElDialog,
    ElIcon,
    ElButton,
    ElFormItem,
    ElUpload,
    UploadFilled,
  },

  props: {
    modelValue: {
      required: true,
      type: Object as PropType<IUpdatePosterForm>,
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const loading = ref(false)

    const elUploadRef = ref()
    const formUploadFile = ref<UploadFile | null>(null)

    const handleFileChange: UploadProps['onChange'] = (uploadFile) => {
      formUploadFile.value = uploadFile
    }

    const onRemoveUploadFile = () => {
      formUploadFile.value = null
      elUploadRef.value.clearFiles()
    }

    const onSubmit = async () => {
      if (!formUploadFile.value) {
        return
      }

      loading.value = true

      try {
        const imageFormData = new FormData()
        imageFormData.append('zpt', formUploadFile.value.raw as Blob)

        const { id } = await filesService.uploadZpt(
          imageFormData,
          `ar-anchores/poster-${props.modelValue.id}/file-${props.modelValue.imageId}`,
        )

        ElNotification({
          message: 'AR Anchor successfully uploaded!',
          type: 'success',
        })

        emit('close', id)
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onOpen = () => {
      onRemoveUploadFile()
    }

    const onClose = () => {
      if (!loading.value) {
        emit('close')
      }
    }

    return {
      loading,
      onOpen,
      onClose,
      elUploadRef,
      formUploadFile,
      maxFileSizesInMb,
      acceptFileFormats,
      handleFileChange,
      Delete,
      onRemoveUploadFile,
      onSubmit,
    }
  },
})
