
import { defineComponent, PropType } from 'vue'
import { ElDialog } from 'element-plus'

export default defineComponent({
  name: 'ImageFullscreenModal',

  components: {
    ElDialog,
  },

  props: {
    modelValue: {
      required: true,
      type: Boolean as PropType<boolean>,
    },

    imageUrl: {
      required: true,
      type: String as PropType<string>,
    },
  },

  emits: ['close'],

  setup(_, { emit }) {
    const onClose = () => {
      emit('close')
    }

    return {
      onClose,
    }
  },
})
