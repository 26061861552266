import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full flex justify-center items-center" }
const _hoisted_2 = { class: "w-full max-w-500" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    "model-value": _ctx.modelValue,
    onClose: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.imageUrl,
            class: "w-full"
          }, null, 8, _hoisted_3)
        ])
      ])
    ]),
    _: 1
  }, 8, ["model-value", "onClose"]))
}