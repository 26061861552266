
import { defineComponent, ref, reactive, onMounted, computed, h } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { v4 as uuidv4 } from 'uuid'
import {
  ElButton,
  ElIcon,
  ElForm,
  ElFormItem,
  FormRules,
  FormInstance,
  ElNotification,
  UploadFile,
  ElSelect,
  ElOption,
  ElMessageBox,
  ElInput,
} from 'element-plus'
import {
  Back,
  Delete,
  Download,
  FullScreen,
  Link,
  CopyDocument,
} from '@element-plus/icons-vue'

import { routeNames } from '@/router'
import {
  IUpdateOrderForm,
  IUpdatePosterForm,
  EPermissions,
  IOrderClientForm,
  IOrderStatusForm,
  IArAncoreProperties,
} from '@/types'
import {
  usePermissions,
  useProducts,
  useOrders,
  useOrderStatuses,
} from '@/core/compositions'
import { apiErrorNotification } from '@/core/helpers'
import { ordersService, filesService } from '@/services'

import Posters from './components/Posters.vue'
import ImageFullscreenModal from './components/ImageFullscreenModal.vue'
import ArAnchorUploadModal from './components/ArAnchorUploadModal.vue'
import UpdateArPropertiesModal from './components/UpdateArPropertiesModal.vue'

export default defineComponent({
  name: 'OrderView',

  components: {
    ElButton,
    ElIcon,
    Back,
    Download,
    Delete,
    FullScreen,
    Link,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,
    ElInput,

    Posters,
    ImageFullscreenModal,
    ArAnchorUploadModal,
    UpdateArPropertiesModal,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()

    const { groupedPermissionsWithLabels, hasPermission } = usePermissions()
    const { getProductsList, productsList } = useProducts()
    const { getOrderstatusesPagination, orderStatuses } = useOrderStatuses()
    const { get } = useOrders()

    const loading = ref(false)
    const fullscreenModalVisible = ref(false)
    const isArchivedValue = ref<boolean>(false)

    const formRef = ref<FormInstance>()
    const statusFormRef = ref<FormInstance>()

    const clientFormValues = reactive<IOrderClientForm>({
      email: '',
      firstName: '',
      lastName: '',
    })
    const orderStatusFormValues = reactive<IOrderStatusForm>({
      id: null,
    })

    const qrCode = reactive({
      id: 0,
      url: '',
      publicUrl: '',
    })

    const formValues = reactive<IUpdateOrderForm>({
      productId: null,
      notes: null,
      posters: [],
    })

    const elUploadRef = ref()
    const formUploadFile = ref<UploadFile | null>(null)
    const formUploadFileSrc = ref(null)

    const uploadArAnchorPoster = ref<IUpdatePosterForm | null>(null)
    const updateArPropertiesPoster = ref<IUpdatePosterForm | null>(null)

    const selectedProduct = computed(() => {
      return productsList.value.find((i) => i.id === formValues.productId)
    })

    const statusFormValidationRules = reactive<FormRules>({
      id: [
        {
          required: true,
          message: 'Status is required!',
          trigger: 'change',
        },
      ],
    })

    const formValidationRules = computed<FormRules>(() => {
      return {
        productId: [
          {
            required: true,
            message: 'Product is required!',
            trigger: 'change',
          },
        ],
        posters: [
          {
            required: true,
            message: 'Posters is required!',
            trigger: 'change',
          },
          {
            type: 'array',
            min: selectedProduct.value?.minPicturesCount || 1,
            max: selectedProduct.value?.maxPicturesCount || 1,
            message: `Posters count should be ${
              selectedProduct.value?.minPicturesCount || 1
            } to ${selectedProduct.value?.maxPicturesCount || 1}`,
            trigger: 'change',
          },
          {
            validator: (
              rule: any,
              value: IUpdatePosterForm[],
              callback: any,
            ) => {
              const posterFilesUploaded = []

              if (
                selectedProduct.value?.imageRequiredInPoster &&
                selectedProduct.value?.videoRequiredInPoster
              ) {
                const isImageVideoUploaded = value.every(
                  (p) => p.imageId && p.videoId,
                )
                posterFilesUploaded.push(isImageVideoUploaded)
              }

              if (selectedProduct.value?.imageRequiredInPoster) {
                const isImageUploaded = value.every((p) => p.imageId)
                posterFilesUploaded.push(isImageUploaded)
              }

              if (selectedProduct.value?.videoRequiredInPoster) {
                const isVideoUploaded = value.every((p) => p.videoId)
                posterFilesUploaded.push(isVideoUploaded)
              }

              const isPostersValid = posterFilesUploaded.every(
                (i) => i === true,
              )
              if (!isPostersValid) {
                callback(
                  new Error(
                    'Please, upload photo and/or video to each poster!',
                  ),
                )
              } else {
                callback()
              }
            },
            message: 'Please, upload photo and/or video to each poster!',
            trigger: 'change',
          },
        ],
      }
    })

    const orderId = computed(() => {
      const orderId = parseInt(route.params?.id as string)

      return !isNaN(orderId) ? orderId : null
    })

    const canAddPosters = computed(() => {
      return (
        selectedProduct.value &&
        (formValues.posters?.length as number) <
          selectedProduct.value?.maxPicturesCount
      )
    })

    onMounted(() => {
      getData()
    })

    const getData = async () => {
      loading.value = true

      try {
        await getOrder()

        await Promise.all([getProductsList(), getOrderstatusesPagination({})])
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const getOrder = async () => {
      if (!orderId.value) {
        ElNotification({
          message: 'Product not found!',
          type: 'error',
        })

        toOrdersPage()
      }

      try {
        const order = await get(orderId.value as number)

        isArchivedValue.value = order.isArchived

        // Set status
        orderStatusFormValues.id = order.status?.id as number

        // Set client
        clientFormValues.email = order?.client?.email
        clientFormValues.firstName = order?.client?.firstName
        clientFormValues.lastName = order?.client?.lastName

        // Set product
        formValues.productId = order.product.id

        // Set notes
        formValues.notes = order.notes as string

        // Set QR-Code
        qrCode.url = order.qrCode.url
        qrCode.id = order.qrCode.id
        qrCode.publicUrl = order.qrCode.publicUrl as string

        // Set posters
        formValues.posters = order.posters.map((poster) => {
          return {
            id: poster.id,
            arAnchoreId: poster?.arAnchore?.id,
            arAnchoreUrl: poster?.arAnchore?.url,
            imageUrl: poster.image?.url,
            videoUrl: poster.video?.url,
            imageId: poster.image?.id,
            videoId: poster.video?.id,
            videoOptimizationStatus: poster.video?.optimizationStatus,
            videoOptimizationError: poster.video?.optimizationError,
            arAnchoreProperties: poster.arAnchoreProperties,
            uuid: uuidv4(),
          }
        })
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)

        toOrdersPage()
      } finally {
        loading.value = false
      }
    }

    const onSubmit = async () => {
      const isFormValid = await formRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      loading.value = true

      try {
        await ordersService.update(orderId.value as number, formValues)

        ElNotification({
          message: 'Order successfully updated!',
          type: 'success',
        })

        getData()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      }
    }

    const toOrdersPage = () => {
      router.push({ name: routeNames.orders })
    }

    const onAddPoster = () => {
      formValues.posters?.push({
        imageId: null,
        videoId: null,
        uuid: uuidv4(),
      })
    }

    const onRemovePoster = (poster: IUpdatePosterForm) => {
      const index = formValues.posters?.findIndex(
        (p) => p.uuid === poster.uuid,
      ) as number
      formValues.posters?.splice(index, 1)
    }

    const onUpdatePosterFileId = (posterData: {
      uuid: string
      fileId: number
      type: 'image' | 'video' | 'arAnchor'
    }) => {
      const poster = formValues.posters?.find(
        (p) => p.uuid === posterData.uuid,
      ) as IUpdatePosterForm

      let fileKey: keyof IUpdatePosterForm = 'imageId'
      let urlKey: keyof IUpdatePosterForm = 'imageUrl'
      if (posterData.type === 'video') {
        fileKey = 'videoId'
        urlKey = 'videoUrl'
      } else if (posterData.type === 'arAnchor') {
        fileKey = 'arAnchoreId'
        urlKey = 'arAnchoreUrl'
      }

      poster[fileKey] = posterData.fileId
      poster[urlKey] = null

      if (fileKey === 'imageId') {
        poster['arAnchoreId'] = null
        poster['arAnchoreUrl'] = null
      }
    }

    const onStatusSubmit = async () => {
      const isFormValid = await statusFormRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      loading.value = true

      try {
        await ordersService.updateStatus(
          orderId.value as number,
          orderStatusFormValues,
        )

        ElNotification({
          message: 'Order status successfully updated!',
          type: 'success',
        })
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const openFullscreenModal = () => {
      fullscreenModalVisible.value = true
    }
    const closeFullscreenModal = () => {
      fullscreenModalVisible.value = false
    }

    const downloadQRCode = () => {
      const anchor = document.createElement<'a'>('a')
      anchor.href = qrCode.url
      anchor.download = `qrcode_${clientFormValues.email}_${orderId.value}`

      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    }

    const onSubmitDelete = async () => {
      const confirm = await ElMessageBox.confirm('Warning', {
        message: h('span', null, [
          'Are you sure you want to archive ',
          h('span', { class: 'font-bold' }, 'order?'),
        ]),
        confirmButtonText: 'Archive',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })

      if (!confirm) {
        return false
      }

      loading.value = true

      try {
        await ordersService.delete(orderId.value as number)

        ElNotification({
          message: 'Product successfully archived!',
          type: 'success',
        })

        toOrdersPage()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onUploadArAnchor = (poster: IUpdatePosterForm) => {
      uploadArAnchorPoster.value = formValues.posters?.find(
        (p) => p.uuid === poster.uuid,
      ) as IUpdatePosterForm
    }

    const closeArAnchorUploadModal = (newARAnchorFileId?: number) => {
      if (newARAnchorFileId) {
        onUpdatePosterFileId({
          uuid: uploadArAnchorPoster.value?.uuid as string,
          fileId: newARAnchorFileId,
          type: 'arAnchor',
        })

        onSubmit()
      }

      uploadArAnchorPoster.value = null
    }

    const createQRCodePublicUrl = async () => {
      loading.value = true

      try {
        await filesService.generatePublicUrl(qrCode.id as number)

        ElNotification({
          message: 'QR Code public URL successfully created!',
          type: 'success',
        })

        getData()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const copyQRCodePublicUrlToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(qrCode.publicUrl)

        ElNotification({
          message: 'QR Code public URL successfully copied to clipboard!',
          type: 'success',
        })
      } catch {
        ElNotification({
          message: 'Error copying to clipboard.',
          type: 'error',
        })
      }
    }

    const onUpdateArProperties = (poster: IUpdatePosterForm) => {
      updateArPropertiesPoster.value = formValues.posters?.find(
        (p) => p.uuid === poster.uuid,
      ) as IUpdatePosterForm
    }

    const closeUpdateArPropertiesModal = (
      newArAncoreProperties?: IArAncoreProperties,
    ) => {
      if (newArAncoreProperties) {
        const poster = formValues.posters?.find(
          (p) => p.uuid === updateArPropertiesPoster.value?.uuid,
        ) as IUpdatePosterForm

        poster.arAnchoreProperties = newArAncoreProperties

        onSubmit()
      }

      updateArPropertiesPoster.value = null
    }

    return {
      loading,
      formValues,
      formValidationRules,
      formRef,
      statusFormRef,
      groupedPermissionsWithLabels,
      onSubmit,
      toOrdersPage,
      formUploadFile,
      formUploadFileSrc,
      Delete,
      elUploadRef,
      productsList,
      canAddPosters,
      onAddPoster,
      onRemovePoster,
      onUpdatePosterFileId,
      hasPermission,
      EPermissions,
      clientFormValues,
      orderStatusFormValues,
      orderStatuses,
      statusFormValidationRules,
      onStatusSubmit,
      qrCode,
      fullscreenModalVisible,
      openFullscreenModal,
      closeFullscreenModal,
      downloadQRCode,
      isArchivedValue,
      onSubmitDelete,
      selectedProduct,
      onUploadArAnchor,
      closeArAnchorUploadModal,
      uploadArAnchorPoster,
      getData,
      createQRCodePublicUrl,
      CopyDocument,
      copyQRCodePublicUrlToClipboard,
      onUpdateArProperties,
      closeUpdateArPropertiesModal,
      updateArPropertiesPoster,
    }
  },
})
