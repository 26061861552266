
import { defineComponent, PropType, ref, reactive } from 'vue'
import { ElDialog, ElButton, FormRules, FormInstance } from 'element-plus'
import { Delete } from '@element-plus/icons-vue'

import { IUpdatePosterForm, IArAncorePropertiesForm } from '@/types'

export default defineComponent({
  name: 'UpdateArPropertiesModal',

  components: {
    ElDialog,
    ElButton,
    // ElFormItem,
    // ElUpload,
  },

  props: {
    modelValue: {
      required: true,
      type: Object as PropType<IUpdatePosterForm>,
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const loading = ref(false)

    const formRef = ref<FormInstance>()

    const formValues = reactive<IArAncorePropertiesForm>({
      width: null,
      height: null,

      positionX: null,
      positionY: null,
      positionZ: null,

      scaleX: null,
      scaleY: null,
      scaleZ: null,

      rotationX: null,
      rotationY: null,
      rotationZ: null,
    })

    const formValidationRule = reactive({
      validator: (rule: any, value: any, callback: any) => {
        if (value >= 0) {
          callback()
        } else {
          callback(new Error('Value must be greater or equal than 0'))
        }
      },
      message: 'Value must be greater or equal than 0',
      trigger: 'change',
    })

    const formValidationRules = reactive<FormRules>({
      width: [formValidationRule],
      height: [formValidationRule],

      positionX: [formValidationRule],
      positionY: [formValidationRule],
      positionZ: [formValidationRule],

      scaleX: [formValidationRule],
      scaleY: [formValidationRule],
      scaleZ: [formValidationRule],

      rotationX: [formValidationRule],
      rotationY: [formValidationRule],
      rotationZ: [formValidationRule],
    })

    const onOpen = () => {
      setFormValues()
    }

    const onClose = () => {
      if (!loading.value) {
        emit('close')
      }
    }

    const setFormValues = () => {
      const anchoreProperties = props.modelValue
        .arAnchoreProperties as IArAncorePropertiesForm

      if (!anchoreProperties) {
        return
      }

      formValues.width = anchoreProperties.width
      formValues.height = anchoreProperties.height

      formValues.positionX = anchoreProperties.positionX
      formValues.positionY = anchoreProperties.positionY
      formValues.positionZ = anchoreProperties.positionZ

      formValues.scaleX = anchoreProperties.scaleX
      formValues.scaleY = anchoreProperties.scaleY
      formValues.scaleZ = anchoreProperties.scaleZ

      formValues.rotationX = anchoreProperties.rotationX
      formValues.rotationY = anchoreProperties.rotationY
      formValues.rotationZ = anchoreProperties.rotationZ
    }

    const onSubmit = async () => {
      const isFormValid = await formRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      emit('close', formValues)
    }

    return {
      loading,
      onOpen,
      onClose,
      formRef,
      Delete,
      onSubmit,
      formValidationRules,
      formValues,
    }
  },
})
