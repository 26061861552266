import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex items-center mt-16" }
const _hoisted_4 = { class: "flex items-center mt-16" }
const _hoisted_5 = { class: "flex items-center mt-16" }
const _hoisted_6 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInputNumber = _resolveComponent("ElInputNumber")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_ElButton = _resolveComponent("ElButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    "model-value": _ctx.modelValue,
    title: "Update AR properties",
    width: "600px",
    "close-on-click-modal": !_ctx.loading,
    "close-on-press-escape": !_ctx.loading,
    "show-close": !_ctx.loading,
    onOpen: _ctx.onOpen,
    onClose: _ctx.onClose
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_6, [
        _createVNode(_component_ElButton, {
          onClick: _ctx.onClose,
          disabled: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        }, 8, ["onClick", "disabled"]),
        _createVNode(_component_ElButton, {
          type: "primary",
          loading: _ctx.loading,
          onClick: _ctx.onSubmit
        }, {
          default: _withCtx(() => [
            _createTextVNode("Update")
          ]),
          _: 1
        }, 8, ["loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ElForm, {
          ref: "formRef",
          model: _ctx.formValues,
          "label-position": "top",
          class: "w-full",
          "status-icon": "",
          rules: _ctx.formValidationRules
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ElFormItem, {
                label: "Width",
                prop: "width",
                class: "w-full max-w-170 mr-16"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: _ctx.formValues.width,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formValues.width) = $event)),
                    modelModifiers: { trim: true },
                    precision: 2,
                    max: 3,
                    min: 0,
                    class: "w-full",
                    size: "large",
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ElFormItem, {
                label: "Height",
                prop: "height",
                class: "w-full max-w-170"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: _ctx.formValues.height,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formValues.height) = $event)),
                    modelModifiers: { trim: true },
                    precision: 2,
                    max: 3,
                    min: 0,
                    class: "w-full",
                    size: "large",
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ElFormItem, {
                label: "Position X",
                prop: "positionX",
                class: "w-full max-w-170 mr-16"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: _ctx.formValues.positionX,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formValues.positionX) = $event)),
                    modelModifiers: { trim: true },
                    precision: 2,
                    min: 0,
                    class: "w-full",
                    size: "large",
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ElFormItem, {
                label: "Position Y",
                prop: "positionY",
                class: "w-full max-w-170 mr-16"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: _ctx.formValues.positionY,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formValues.positionY) = $event)),
                    modelModifiers: { trim: true },
                    precision: 2,
                    min: 0,
                    class: "w-full",
                    size: "large",
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ElFormItem, {
                label: "Position Z",
                prop: "positionZ",
                class: "w-full max-w-170"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: _ctx.formValues.positionZ,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formValues.positionZ) = $event)),
                    modelModifiers: { trim: true },
                    precision: 2,
                    min: 0,
                    class: "w-full",
                    size: "large",
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ElFormItem, {
                label: "Scale X",
                prop: "scaleX",
                class: "w-full max-w-170 mr-16"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: _ctx.formValues.scaleX,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formValues.scaleX) = $event)),
                    modelModifiers: { trim: true },
                    precision: 2,
                    min: 0,
                    class: "w-full",
                    size: "large",
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ElFormItem, {
                label: "Scale Y",
                prop: "scaleY",
                class: "w-full max-w-170 mr-16"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: _ctx.formValues.scaleY,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formValues.scaleY) = $event)),
                    modelModifiers: { trim: true },
                    precision: 2,
                    min: 0,
                    class: "w-full",
                    size: "large",
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ElFormItem, {
                label: "Scale Z",
                prop: "scaleZ",
                class: "w-full max-w-170"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: _ctx.formValues.scaleZ,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formValues.scaleZ) = $event)),
                    modelModifiers: { trim: true },
                    precision: 2,
                    min: 0,
                    class: "w-full",
                    size: "large",
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_ElFormItem, {
                label: "Rotation X",
                prop: "rotationX",
                class: "w-full max-w-170 mr-16"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: _ctx.formValues.rotationX,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formValues.rotationX) = $event)),
                    modelModifiers: { trim: true },
                    precision: 2,
                    min: 0,
                    class: "w-full",
                    size: "large",
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ElFormItem, {
                label: "Rotation Y",
                prop: "rotationY",
                class: "w-full max-w-170 mr-16"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: _ctx.formValues.rotationY,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formValues.rotationY) = $event)),
                    modelModifiers: { trim: true },
                    precision: 2,
                    min: 0,
                    class: "w-full",
                    size: "large",
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ElFormItem, {
                label: "Rotation Z",
                prop: "rotationZ",
                class: "w-full max-w-170"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElInputNumber, {
                    modelValue: _ctx.formValues.rotationZ,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formValues.rotationZ) = $event)),
                    modelModifiers: { trim: true },
                    precision: 2,
                    min: 0,
                    class: "w-full",
                    size: "large",
                    "controls-position": "right"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ]),
    _: 1
  }, 8, ["model-value", "close-on-click-modal", "close-on-press-escape", "show-close", "onOpen", "onClose"]))
}